// Print Stylesheet
@media print {
  @page {
    margin: 0.5cm;
  }

  body {
    font: 13pt;
    line-height: 1.3;
  }

  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    -ms-filter: none !important;
    filter: none !important;
  }
  // Black prints faster: h5bp.com/s
  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  .ir a:after,
  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }
  // Don't show links for images, or javascript/internal links
  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  *,
  *:after,
  *:before,
  *:first-letter,
  blockquote:first-line,
  div:first-line,
  li:first-line,
  p:first-line {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important;
  }

  ul {
    margin-bottom: 20pt;
    margin-left: 18pt;
    list-style: square;
  }

  li {
    line-height: 1.6em;
  }

  .entry iframe,
  ins {
    display: none;
    overflow: hidden !important;
    width: 0 !important;
    height: 0 !important;
    white-space: nowrap;
    line-height: 0 !important;
  }

  .embed-responsive,
  .embed-youtube {
    position: absolute;
    overflow: hidden;
    height: 0;
  }

  .copyright,
  .scrollup,
  footer,
  header,
  nav {
    display: none;
  }

  section {
    float: none;
    margin: 0;
    width: 100%;
  }
}
